import React, {useContext, useEffect, useState} from 'react';
import Loading from "../Layout/Loading/Loading";
import PageLayout from "../Layout/PageLayout/PageLayout";
import ContentBox from "../Layout/ContentBox/ContextBox";
import {request} from "../../Utility/Api";
import Notice from "../Layout/Notice/Notice";
import IntroText from "../Layout/IntroText/IntroText";
import {AppState} from "../State/AppState";
import PasswordInput from "../../Form/PasswordInput";
import Button from "../../Form/Button";
import TextInput from "../../Form/TextInput";

export default function Verify(props) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [globalAppState] = useContext(AppState);
    const [password, setPassword] = useState('');
    const [user, setUser] = useState({});
    const [saving, setSaving] = useState(false);
    useEffect(() => {
        request('GET', 'customers/register/verify/' + props.token, {}).then((response) => {
            if (response.ok) {
                setUser(response.data.data);
            } else {
                setError(response.error);
            }
            setLoading(false);
        });
    }, [props.token]);

    const handleSave = (e) => {
        e.preventDefault();
        if (!password || password.length < 8) {
            setError('Password must be at least 8 characters');
            return;
        }
        setError(null);
        setSaving(true);
        request('POST', 'customers/register/verify/' + props.token, {
            body: {
                customer_id: user.id,
                password: password
            }
        }).then((response) => {
            if (response.ok) {
                props.history.push('/auth');
                return;
            }
            setError(response.error);
            setSaving(false);
        });
    }

    if (loading) {
        return <Loading/>
    }

    return (<PageLayout {...props}>
        {error ? <>
            <ContentBox>
                <Notice name="error">{error}</Notice>
            </ContentBox>
        </> : null}
        {user.id ? <>
            <ContentBox>
                <IntroText name="enter-password">
                    Sweet, enter a password and you'll be done! Try to make it unique, and not just your
                    favourite drink ({globalAppState.brand.drinks[0]} obviously)
                </IntroText>
                <form onSubmit={(e) => handleSave(e)}>
                    <TextInput disabled={true} name="email" value={user.email}/>
                    <PasswordInput name="password" value={password} onChange={(e) => setPassword(e.target.value)}
                                   placeholder="Enter Password..." disabled={saving}/>
                    <Button name="verify" variant="full-width" text="Set Password" type="submit" disabled={saving}/>
                </form>
            </ContentBox>
        </> : null}
    </PageLayout>)
}