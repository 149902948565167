import React from 'react';
import PropTypes from 'prop-types';
import cx from "classnames";

export default function PasswordInput(props) {
    let className = props.className;
    if (!className) {
        className = 'field__element';
    }
    let id = props.id;
    if (!id) {
        id = props.name;
    }

    let params = {
        name: props.name,
        id: id,
        value: props.value,
        className: className,
        placeholder: props.placeholder,
        disabled: props.disabled,
        maxLength: props.maxLength
    };
    if (props.onChange) {
        params.onChange = props.onChange;
    }
    if (props.onBlur) {
        params.onBlur = props.onBlur;
    }
    if (props.onFocus) {
        params.onFocus = props.onFocus;
    }

    return <div className={cx('field', 'field-text', 'field--' + props.name, props.wrapperClass)}>
        <div className="field__element-wrap">
            <input type="password" {...params}/>
        </div>
    </div>
}


PasswordInput.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    id: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    placeholder: PropTypes.string,
    max: PropTypes.number,
    min: PropTypes.number,
    maxLength: PropTypes.number
}