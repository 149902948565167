import React, {useContext, useState} from 'react';
import {AppState} from "../../State/AppState";
import Button from "../../../Form/Button";
import {request} from "../../../Utility/Api";
import Notice from "../../Layout/Notice/Notice";
import {EventDispatcher} from "../../../Utility/Dispatcher";
import './AmigoStatus.scss'

export default function AmigoStatus(props) {
    const [globalAppState] = useContext(AppState);
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState(null);
    let user = globalAppState.user;
    let company = user.amigo.data.company.data;
    return (
        <>
            {error ? <Notice variant="error" name="error">{error}</Notice> : null}
            <div className="AmigoStatus-container">
                <div className="AmigoStatus-header">
                    <h1>Hola Amigo!</h1>
                    <p>You are currently attached to: <br/><strong>{company.name}</strong>.</p>
                </div>

                <img className="AmigoStatus-lime" src="/assets/images/sad-lime.svg"/>
                <p className="AmigoStatus-leave">If you wish to leave this company and lose those sweet, sweet discounts click below!</p>

                <Button name="leave-amigo" text="Leave Company" disabled={saving} onClick={() => {
                    setSaving(true);
                    request('DELETE', 'customers/customer-amigo', {}).then((response) => {
                        if (response.ok) {
                            EventDispatcher.dispatch('reload-app-data');
                            return;
                        }
                        setError(response.error);
                    })
                }} variant="full-width"/>
            </div>
        </>
    )
}