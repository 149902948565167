import React, {useState} from 'react';
import PageLayout from "../Layout/PageLayout/PageLayout";
import ContentBox from "../Layout/ContentBox/ContextBox";
import TextInput from "../../Form/TextInput";
import PasswordInput from "../../Form/PasswordInput";
import Button from "../../Form/Button";
import {request} from "../../Utility/Api";
import {withRouter} from 'react-router-dom';
import Notice from "../Layout/Notice/Notice";
import {EventDispatcher} from "../../Utility/Dispatcher";

export default withRouter(function Login(props) {
        const [credentials, setCredentials] = useState({
            username: '',
            password: ''
        });
        const [error, setError] = useState(null);

        const login = (e) => {
            e.preventDefault();
            request('POST', 'customers/authenticate', {
                body: {email: credentials.username, password: credentials.password}
            }).then((response) => {
                if (response.ok) {
                    EventDispatcher.dispatch('reload-app-data');
                    props.history.push('/');
                    return;
                }
                setError(response.error);
            })
        }

        return (<PageLayout {...props}>
            <ContentBox>
                {error ? <Notice variant="error">{error}</Notice> : null}
                <form onSubmit={(e) => login(e)}>
                    <TextInput name="username" value={credentials.username}
                               placeholder="Username"
                               onChange={(e) => setCredentials({...credentials, username: e.target.value})}/>
                    <PasswordInput name="password" value={credentials.password}
                                   placeholder="Password"
                                   onChange={(e) => setCredentials({...credentials, password: e.target.value})}/>
                    <Button name="login" text="Login" variant="full-width" type="submit"/>
                </form>
            </ContentBox>
        </PageLayout>)
    }
);