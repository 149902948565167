import React, {useContext, useEffect, useState} from 'react';
import Button from "../../Form/Button";
import {request} from "../../Utility/Api";
import Notice from "../Layout/Notice/Notice";
import {EventDispatcher} from "../../Utility/Dispatcher";
import PageLayout from "../Layout/PageLayout/PageLayout";
import ContentBox from "../Layout/ContentBox/ContextBox";
import Loading from "../Layout/Loading/Loading";
import {AppState} from "../State/AppState";

export default function JoinAmigoToken(props) {
    const [loginState] = useContext(AppState);
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [loginRequired, setLoginRequired] = useState(false);
    const [alreadyAssigned, setAlreadyAssigned] = useState(false);

    const joinAmigo = () => {
        setSaving(true);
        setError(false);
        request('POST', 'customers/join-amigo/qr/' + props.token, {}).then((response) => {
            setSaving(false);
            setError(null);
            if (response.ok) {
                document.location.href = '/';
                return;
            }
            setError(response.error ? response.error : 'An Error Occurred. Please try again later');
        });
    }
    const [company, setCompany] = useState({});
    useEffect(() => {
        if (loginState.logged_in && loginState.user.amigo.data.id) {
            setAlreadyAssigned(true);
            setError('You are already assigned to ' + loginState.user.amigo.data.company.data.name)
            setLoading(false);
            return;
        }
        request('GET', 'customers/join-amigo/qr/' + props.token, {}).then((response) => {
            if (response.code === 404) {
                setError(response.error);
                setLoading(false);
                return;
            }
            if (response.code === 403) {
                setLoginRequired(true);
                setLoading(false);
                return;
            }

            if (response.status === 200) {
                setLoading(false);
                setCompany(response.data.data);
                return;
            }

            if (response.code === 400) {
                setError(response.error);
                setAlreadyAssigned(true);
                setLoading(false);
            }

        });
    }, [loginState.user]);
    if (loading) {
        return (
            <PageLayout {...props}>
                <ContentBox name="join-amigo">
                    <Loading/>
                </ContentBox>
            </PageLayout>
        )
    }

    if (alreadyAssigned) {
        return (
            <PageLayout {...props}>
                <ContentBox name="join-amigo-register">
                    <Notice name="leave-amigo" variant="error">{error}</Notice>
                    <Button text="Leave Company" variant="full-width" name="leave-amigo" onClick={() => {
                        request('DELETE', 'customers/customer-amigo', {}).then((response) => {
                            EventDispatcher.dispatch('reload-app-data');
                        });
                    }}/>
                </ContentBox>
            </PageLayout>
        )
    }

    if (error) {
        return (
            <PageLayout {...props}>
                <ContentBox name="join-amigo">
                    <Notice name="error" variant="error">{error}</Notice>
                </ContentBox>
            </PageLayout>
        )
    }

    if (loginRequired) {
        return (
            <PageLayout {...props}>
                <ContentBox name="join-amigo-register">
                    <Notice name="register-required">You need to login or register to continue!</Notice>

                    <Button name="join" text="Login" disabled={saving} onClick={() => document.location.href = '/auth'}
                            variant="full-width"/>
                    <Button name="register" text="Register" disabled={saving}
                            onClick={() => document.location.href = '/register-amigo'}
                            variant="full-width btn--outline"/>
                </ContentBox>
            </PageLayout>
        )
    }
    return (<PageLayout {...props}>
        <ContentBox name="join-amigo">
            <Notice name="join-confirm">Hola Amigo! You are about to join <strong>{company.name}'s</strong> amigo
                scheme and get access to those sweet discounts! Want to continue?</Notice>
            <Button name="do-join" text="Si Señor!" variant="full-width" onClick={() => joinAmigo()}/>
        </ContentBox>
    </PageLayout>)
}