function request(method, endpoint, {body, ...customConfig}) {
    const headers = {'content-type': 'application/json'}
    const config = {
        method: method,
        ...customConfig,
        headers: {
            ...headers,
            ...customConfig.headers,
            'X-Brand': '2',
            'X-Host': window.location.host
        },
    }
    if (body) {
        config.body = JSON.stringify(body)
    }
    return window
        .fetch(`/api/${endpoint}`, config)
        .then(async response => {
            if (response.status === 401) {
                window.location.assign(window.location)
                return
            }
            const data = await response.json()
            if (response.ok) {
                return {
                    data: data,
                    ok: response.ok,
                    status: response.status
                }
            } else {
                let error = null;
                if (data.errors && data.errors.fields && data.errors.fields.length > 0) {
                    error = data.errors.fields.map(function (e) {
                        return e.error;
                    }).join(', ');
                } else if (data.errors && data.errors.message) {
                    error = data.errors.message;
                }
                return Promise.reject({code: response.status, ok: false, data: data, error: error})
            }
        }).catch((response) => {
            return response;
        });
}

export {request}