import React, {useContext, useEffect, useState} from 'react';
import {AppState} from "../State/AppState";
import Loading from "../Layout/Loading/Loading";
import ContentBox from "../Layout/ContentBox/ContextBox";
import PageLayout from "../Layout/PageLayout/PageLayout";
import Notice from "../Layout/Notice/Notice";
import Button from "../../Form/Button";

export default function Book(props) {
    const [loading, setLoading] = useState(true);
    const [globalAppState] = useContext(AppState);
    const [height, setHeight] = useState(400);
    const [newBooking, setNewBooking] = useState(true);
    const initBookings = () => {
        let session_id = window.localStorage.getItem('bookings_session_id');
        let initial_session_id = window.localStorage.getItem('bookings_session_id');
        let new_booking_value = initial_session_id === 'null' || initial_session_id === '' || initial_session_id === null;
        setNewBooking(new_booking_value);
        setLoading(false);
        let pre_filled_data = {
            customer_id: globalAppState.user.rb_customer_id,
            email: globalAppState.user.email,
            source: 'app'
        };
        if (!new_booking_value) {
            pre_filled_data.session_id = initial_session_id;
        }
        if (globalAppState.user.id && globalAppState.user.favourite_bars.data.length === 1) {
            pre_filled_data.bar_id = globalAppState.user.favourite_bars.data[0].bar_id;
        }
        window.Bookings.init('#booking_form', {
            brand: globalAppState.brand.short_name,
            pre_filled_data: pre_filled_data,
            source: 'app',
            form_url: '/book-app',
            sizeUpdate: (size) => {
                setHeight(size.height);
            }
        });
        window.addEventListener('booking_form_event', (data) => {
            if (data.detail.type === 'booking_update' && (!session_id || session_id === 'null') && data.detail.value.booking.session_id) {
                session_id = data.detail.value.booking.session_id;
                window.localStorage.setItem('bookings_session_id', session_id);
            }
        })
    }
    useEffect(() => {
        window.load_script('https://' + globalAppState.brand.booking_domain + '/js/bookings.js', (e) => {
            initBookings();
        });
    }, [props.history.location.pathname]);

    return (<PageLayout {...props}>
        <ContentBox name="book">
            {loading ? <Loading/> : null}
            {!newBooking ? <Notice name="restarted-session">
                We noticed you didn't complete your last booking, we've picked it up where you left off!
                <Button text="Start New Booking" name="start-new" variant="text"
                        onClick={() => {
                            window.localStorage.removeItem('bookings_session_id');
                            document.location.reload();
                        }}/>
            </Notice> : null}
            <div id="booking_form" style={{height: height + 'px'}}/>
        </ContentBox>
    </PageLayout>)
}