import React, {useEffect, useState} from 'react';
import Loading from "../Layout/Loading/Loading";
import {request} from "../../Utility/Api";

const BarLanding = (props) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        request('POST', 'customers/web-app/bar', {body: {slug: props.match.params.slug}}).then((response) => {
            if (response.ok) {
                if (response.data.uri) {
                    document.location.href = response.data.uri;
                }
                document.location.href = '/';
                return;
            }

            document.location.href = '/';
        });
    }, [props.match.params.slug])
    if (loading) {
        return <Loading/>
    }
}

export default BarLanding;