import { withRouter } from 'react-router-dom'
import PageLayout from '../Layout/PageLayout/PageLayout'
import ContentBox from '../Layout/ContentBox/ContextBox'
import React, { useState } from 'react'
import { request } from '../../Utility/Api'
import TextInput from '../../Form/TextInput'
import Button from '../../Form/Button'
import Notice from '../Layout/Notice/Notice'

export default withRouter(function ForgotPassword (props) {
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const [error, setError] = useState('')
    const [loading,setLoading] = useState(false);

    const reset = (e) => {
        e.preventDefault()
        setLoading(true);
        request('POST', 'customers/forgot-password', {
            body: { email: email, signup_portal: 'app' },
        }).then((response) => {
            setLoading(false);
            if (response.ok) {
                setMessage('Please check your e-mail to complete the password reset process')
                setEmail('');
                return;
            }
            setError(response.error)
        })
    }

    return (<PageLayout {...props}>
        <ContentBox title="Enter your e-mail address to continue">
            {message && <Notice name="message" variant="info">{message}</Notice>}
            {error && <Notice name="error" variant="error">{error}</Notice>}
            <form onSubmit={(e) => reset(e)}>
                <TextInput name="email" value={email}
                           placeholder="E-mail address"
                           onChange={(e) => setEmail(e.target.value)}/>
                <Button name="login" text="Reset My Password" variant="full-width" type="submit" disabled={loading}/>
            </form>
        </ContentBox>
    </PageLayout>)
})