import React, { useContext, useEffect, useState } from "react";
import { AppState } from "../State/AppState";
import Loading from "../Layout/Loading/Loading";
import { request } from "../../Utility/Api";
import { EventDispatcher } from "../../Utility/Dispatcher";
import AddToWallet from "./AddToWallet.svg";
import "./MyQrCode.css";
import Notice from "../Layout/Notice/Notice";

export default function MyQrCode(props) {
  const [globalAppState] = useContext(AppState);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  let user = globalAppState.user;
  if (!user.rb_customer_id) {
    document.location.href = "/";
  }

  useEffect(() => {
    if (!user.qr_code.data.id) {
      request("POST", "customers/create-qr", {}).then((response) => {
        if (response.ok) {
          EventDispatcher.dispatch("reload-app-data");
          setLoading(false);
          return;
        }
        setLoading(false);
        setError(
          "There was an error in creating your QR code, please try again later"
        );
      });
      return;
    }
    setLoading(false);
  }, []);

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return (
      <div className="my-qr-code">
        <Notice name="error" variant="error">
          {error}
        </Notice>
      </div>
    );
  }

  const iOSchecker = () => {
   return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  };

  const iOSwallet = (
    <div className="ios-wallet">
      <a href={user.pkpass.data.uri} rel="noreferrer" target="_blank">
        <img src={AddToWallet} alt="Add To Wallet" />{" "}
      </a>
    </div>
  );

  return (
    <div className="my-qr-code">
      <img
        src={user.qr_code.data.uri}
        alt="Scan Me at the till"
        className="qr-code"
      />
      {/*{iOSchecker() && (user.pkpass.data.id ? iOSwallet : null)}*/}
    </div>
  );
}
