import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import './Panel.scss';
import PanelBox from "./PanelBox";
import {withRouter} from 'react-router-dom';
import {AppState} from "../State/AppState";
import {ModalHelper} from "../../Utility/Modal/AppModal";
import MyQrCode from "../QrCode/MyQrCode";


const Panel = function (props) {
    const [globalAppState] = useContext(AppState);
    let user = globalAppState.user;
    let panel = props.panel;
    let module = panel.type.data.module.toLowerCase();
    if (module === 'image') {
        return <PanelBox panel={panel} image={panel.image.data.url} title={panel.title} sub_title={panel.sub_title}
                         action={(e) => {
                             e.preventDefault();
                             let target = panel.config.target;
                             if (target) {
                                 if (target.substr(0, 8) === 'https://' || target.substr(0, 7) === 'http://') {
                                     window.open(target);
                                 } else {
                                     props.history.push(panel.config.target);
                                 }
                             }
                         }}/>
    }
    if (module === 'book') {
        return <PanelBox panel={panel} image={panel.image.data.url} title={panel.title} sub_title={panel.sub_title}
                         action={(e) => {
                             e.preventDefault();
                             props.history.push('/book');
                         }}/>
    }
    if (module === 'register') {
        return <PanelBox panel={panel} image={panel.image.data.url} title={panel.title} sub_title={panel.sub_title}
                         action={(e) => {
                             e.preventDefault();
                             props.history.push('/register-amigo');
                         }}/>
    }
    if (module === 'login') {
        return <PanelBox panel={panel} image={panel.image.data.url} title={panel.title} sub_title={panel.sub_title}
                         action={(e) => {
                             e.preventDefault();
                             props.history.push('/auth');
                         }}/>
    }
    if (module === 'location') {
        return <PanelBox panel={panel} image={panel.image.data.url} title={panel.title} sub_title={panel.sub_title}
                         action={(e) => {
                             e.preventDefault();
                             props.history.push('/locations');
                         }}/>
    }

    if (module === 'amigo') {
        return <PanelBox panel={panel} image={panel.image.data.url} title={panel.title} sub_title={panel.sub_title}
                         action={(e) => {
                             e.preventDefault();
                             props.history.push('/amigo');
                         }}/>
    }

    if (module === 'qrcode') {
        if (user) {
            return <PanelBox panel={panel} image={panel.image.data.url} title={panel.title}
                             sub_title={panel.sub_title}
                             action={(e) => {
                                 e.preventDefault();
                                 ModalHelper.openModal(<MyQrCode/>);
                             }}/>
        }
    }
    return (
        <div className="panel">
            Unknown Panel
        </div>
    );
}

export default withRouter(Panel);

Panel.propTypes = {
    panel: PropTypes.object.isRequired
}