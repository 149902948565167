import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import SelectInput from "./SelectInput";
import {DateTime} from "luxon";
import './DateInput.scss';

export default function DateInput(props) {
    let [value, setValue] = useState(props.value);
    let [day, setDay] = useState(props.value.split('-')[2]);//props.value ? DateTime.fromISO(props.value).toFormat('dd') : '');
    let [month, setMonth] = useState(props.value.split('-')[1]);//props.value ? DateTime.fromISO(props.value).toFormat('mm') : '');
    let [year, setYear] = useState(props.value.split('-')[0]);//props.value ? DateTime.fromISO(props.value).toFormat('yyyyy') : '');
    const range = (size, startAt) => {
        return [...Array(size).keys()].map(i => i + startAt);
    }
    let days = range(31, 1).map((d) => {
        if (d < 10) {
            d = '0' + d;
        } else {
            d = '' + d;
        }
        return {
            key: d,
            value: d
        }
    });
    let years = range(100, DateTime.now().toFormat('yyyy') - 99).map((d) => {
        return {
            key: d,
            value: d
        }
    });
    useEffect(() => {
        let parts = props.value.split('-');
        setDay(parts[2]);
        setMonth(parts[1]);
        setYear(parts[0]);
        setValue(props.value);
    }, [props.value]);

    useEffect(() => {
        if (props.onChange) {
            props.onChange({
                target: {
                    name: props.name,
                    value: year + '-' + month + '-' + day
                }
            })
        }
    }, [day, month, year]);
    let months = [
        {key: '01', value: 'January'},
        {key: '02', value: 'February'},
        {key: '03', value: 'March'},
        {key: '04', value: 'April'},
        {key: '05', value: 'May'},
        {key: '06', value: 'June'},
        {key: '07', value: 'July'},
        {key: '08', value: 'August'},
        {key: '09', value: 'September'},
        {key: '10', value: 'October'},
        {key: '11', value: 'November'},
        {key: '12', value: 'December'},
    ]
    return <div className="date-input field--wrapper">
        <SelectInput name="day" value={day} values={days}
                     includeBlankOption={true}
                     onChange={(e) => {
                         setDay(e.target.value);
                     }}
        />
        <SelectInput name="month" value={month} values={months}
                     includeBlankOption={true}
                     onChange={(e) => {
                         setMonth(e.target.value);
                     }}
        />
        <SelectInput name="year" value={year} values={years}
                     includeBlankOption={true}
                     onChange={(e) => {
                         setYear(e.target.value);
                     }}
        />
    </div>
}

DateInput.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired
}