import React, {useState} from 'react';
import TextInput from "../../Form/TextInput";
import Button from "../../Form/Button";
import {request} from "../../Utility/Api";
import Notice from "../Layout/Notice/Notice";
import {EventDispatcher} from "../../Utility/Dispatcher";

export default function JoinAmigoCode(props) {
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState(null);
    const checkAmigo = () => {
        setSaving(true);
        setError(null);
        request('POST', 'customers/join-amigo/code', {body: {code: inviteCode}}).then((response) => {
            setSaving(false);
            setError(null);
            if (response.ok) {
                EventDispatcher.dispatch('reload-app-data');
                return;
            }
            setError(response.error ? response.error : 'An Error Occurred. Please try again later');
        });
    }
    const [inviteCode, setInviteCode] = useState('');
    return (<>
        {error ? <Notice variant="error" name="amigo-join-error">{error}</Notice> : null}
        <TextInput name="inviteCode" value={inviteCode} onChange={(e) => setInviteCode(e.target.value)}
                   placeholder="Enter your invite code here" disabled={saving}/>
        <Button name="join" text="Join Amigo" disabled={saving} onClick={() => checkAmigo()} variant="full-width"/>
    </>)
}