import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {request} from "../../Utility/Api";
import {EventDispatcher} from "../../Utility/Dispatcher";

export default function LocationPanel(props) {
    let location = props.location;
    const handleFavourite = (e) => {
        request('POST', 'customers/favourite', {body: {bar_id: location.bar_id}}).then((response) => {
            if (response.ok) {
                EventDispatcher.dispatch('reload-app-data');
            }
        })
    }
    return <div className={cx('location-panel', {fav: location.favourite})}>
        <div className="location-panel--info">
            <div className="location-panel--title">{location.bar_name}</div>
            <div className="location-panel--address">{location.address.join(', ')}</div>
        </div>
        <div className="location-panel--favourite">
            <button className="add-favourite" onClick={(e) => handleFavourite(e)}>
                <i className={cx('fa fa-star-o', {fav: location.favourite})}/>
            </button>
        </div>
    </div>
}

LocationPanel.propTypes = {
    location: PropTypes.object.isRequired
}