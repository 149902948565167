import React, {useContext} from 'react';
import cx from 'classnames';
import {DateTime} from "luxon";
import {withRouter} from 'react-router-dom';
import {AppState} from "../../State/AppState";
import {request} from "../../../Utility/Api";
import {EventDispatcher} from "../../../Utility/Dispatcher";

import './PageLayout.scss'

const PageLayout = (props) => {
    const [globalAppState] = useContext(AppState);
    let portal_class = props.name ? 'portal-page--' + props.name : '';
    let variant = '';
    if (props.variant) {
        variant = 'portal-page--' + props.variant;
    }
    return (<>
        <div className={cx('portal-page', portal_class, variant, {'logged-in': false})}>
            <header>
                <div className="container">
                    {props.history.location.pathname !== '/' ? <button className="back-button" onClick={() => {
                        props.history.goBack();
                    }}>
                        <i className="fa fa-arrow-left"/>
                        <span>Back</span>
                    </button> : null}
                    <button className="header-logo" onClick={(e) => {
                        e.preventDefault()
                        props.history.push('/');

                    }}>
                        <img src="/assets/images/logo-white.svg" alt={globalAppState.brand.name}/>
                    </button>
                    {globalAppState.user.rb_customer_id ?
                        <button className="logout" onClick={() => {
                            request('POST', 'customers/logout', {}).then((response) => {
                                EventDispatcher.dispatch('reload-app-data');
                            });
                        }}><i className="fa fa-sign-out"/></button> : null}
                </div>
            </header>
                <div className="PageLayout-separator">
                    <main className="container">
                        <div className={cx('main-content', props.mainContentClassName)}>
                            {props.children}
                        </div>
                    </main>
                    <footer>
                        <div className="container">
                            <div className="copy">&copy; Revolution Bars Group {DateTime.now().toFormat('yyyy')}</div>
                        </div>
                    </footer>
                </div>
        </div>
    </>)
}

export default withRouter(PageLayout);