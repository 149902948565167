import React, {useState} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

export default function Checkbox(props) {
    const [checked, setChecked] = useState(!!props.value);
    let params = {
        name: props.name,
        id: 'cb_' + props.name,
        className: 'field__element'
    };
    params.onChange = (e) => {
        setChecked(e.target.checked);
        if (props.onChange) {
            props.onChange({
                target: {
                    name: props.name,
                    value: e.target.checked === true
                }
            })
        }
    }
    if (checked) {
        params.checked = true;
        params.value = true;
    } else {
        params.checked = false;
        params.value = false;
    }
    if (props.label_after === true) {
        return <div
            className={cx('field', 'field--checkbox', 'field--' + props.name, {'field--checked': !!checked})}>
            <div className="field__element-wrap">
                <input type="checkbox" {...params}/>
                <label htmlFor={params.id} className="field__sub-label">{props.label} - {checked ? 'Yes' : 'No'}</label>
            </div>
        </div>
    } else {
        return <div
            className={cx('field', 'field--checkbox', 'field--' + props.name, {'field--checked': !!checked})}>
            <div className="field__element-wrap">
                <input type="checkbox" {...params}/>
                <label htmlFor={params.id} className="field__sub-label">{props.label} - {checked ? 'Yes' : 'No'}</label>
            </div>
        </div>
    }
}
Checkbox.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    label: PropTypes.string.isRequired,
    value: PropTypes.bool,
    label_after: PropTypes.bool
}