import React, {useContext, useState} from 'react';
import PageLayout from "../Layout/PageLayout/PageLayout";
import ContentBox from "../Layout/ContentBox/ContextBox";
import Notice from "../Layout/Notice/Notice";
import {AppState} from "../State/AppState";
import JoinAmigoCode from "./JoinAmigoCode";
import AmigoStatus from "./AmigoStatus/AmigoStatus";

export default function Amigo(props) {
    const [globalAppState] = useContext(AppState);
    const [error, setError] = useState(null);
    let user = globalAppState.user;
    if (!user.rb_customer_id) {
        props.history.push('/');
    }
    return (<PageLayout {...props}>
        <ContentBox name="amigo">
            {error ? <Notice variant="error" name="amigo-error">{error}</Notice> : null}
            {!user.amigo.data.id ? <JoinAmigoCode/> : <AmigoStatus/>}
        </ContentBox>
    </PageLayout>)
}