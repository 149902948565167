import React, {useState} from 'react';
import TextInput from "../../Form/TextInput";
import PageLayout from "../Layout/PageLayout/PageLayout";
import ContentBox from "../Layout/ContentBox/ContextBox";
import './Register.css';
import Button from "../../Form/Button";
import {request} from "../../Utility/Api";
import Notice from "../Layout/Notice/Notice";
import DateInput from "../../Form/DateInput";
import {DateTime} from "luxon";
import Checkbox from "../../Form/Checkbox";

export default function RegisterAmigo(props) {
    const [details, setDetails] = useState({
        forename: '',
        surname: '',
        email: '',
        mobile: '',
        dob: DateTime.now().toFormat('yyyy-MM-dd'),
        signup_portal: 'app',
        marketing_opt_in: false,
        sms_opt_in: false,
        phone_opt_in: false
    });
    const [emailChecked, setEmailChecked] = useState(false);
    const [emailChecking, setEmailChecking] = useState(false);
    const [alreadyRegistered, setAlreadyRegistered] = useState(false);
    const [completed, setCompleted] = useState(false);
    const [error, setError] = useState(null);
    const [forgotPassword, setShowForgot] = useState(false);
    const [saving, setSaving] = useState(false);
    const [forgotSent, setForgotSent] = useState(false);
    const doRegister = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (!emailChecked) {
            checkEmail();
            return;
        }
        setSaving(true);
        request('POST', 'customers/amigo/register', {body: details}).then((response) => {
            setSaving(false);
            if (response.ok) {
                setCompleted(true);
                return;
            }
            if (response.error === 'You are already registered') {
                setShowForgot(true);
            }
            setError(response.error);
        });
    }

    const checkEmail = () => {
        setEmailChecking(true);
        setAlreadyRegistered(false);
        setEmailChecked(false);
        request('GET', 'customers/customer-email/check?email=' + details.email, {}).then((response) => {
            setEmailChecking(false);
            if (response.ok) {
                if (response.data.registered === false) {
                    setEmailChecked(true);
                }
                if (response.data.registered === true) {
                    setAlreadyRegistered(true);
                }
            }
        });
    }
    return (
        <PageLayout {...props} className="">
            <div className="Registration-main"> 
            <ContentBox name="register-intro">
                <h1>Registration</h1>
                <p>Enter your details below and you'll be registered before you know it!</p>
            </ContentBox>
            {error ? <ContentBox name="register-error">
                <Notice variant="error" name="register-error">
                    {error}
                    {forgotPassword ? <div className="inline-buttons">
                        <Button name="forgot-password" text="Send Password Reset" onClick={() => {
                            setSaving(true);
                            setError(null);
                            request('POST', 'customers/forgot-password', {
                                body: {
                                    email: details.email,
                                    signup_portal: 'app'
                                }
                            }).then((response) => {
                                setSaving(false);
                                if (response.ok) {
                                    setForgotSent(true);
                                    return;
                                }
                            })
                        }}/>
                        <Button name="login" text="Login" onClick={() => {
                            document.location.href = '/login';
                        }}/>
                    </div> : null}
                </Notice>
            </ContentBox> : null}
            {completed ? <ContentBox name="register">
                    <Notice variant="success" name="email_check">Almost there! Check your e-mail and click on the link to
                        confirm your
                        membership</Notice>
                </ContentBox> :
                <ContentBox name="register">
                    {forgotSent ?
                        <Notice variant="success" name="email_check">Check your e-mail for password reset
                            instructions</Notice> : null}
                    <form onSubmit={(e) => doRegister(e)} method="POST">
                        <TextInput name="email" value={details.email}
                                   placeholder="E-mail address *" email={true}
                                   disabled={saving || emailChecking}
                                   onChange={(e) => setDetails({...details, email: e.target.value})}
                                   onBlur={(e) => checkEmail(e)}/>
                        {alreadyRegistered ? <>
                            <Notice name="already-logged-in">
                                You are already registered!
                                <Button text="Login Here" name="login" onClick={() => document.location.href = '/auth'}
                                        variant="full-width"/>
                            </Notice>
                        </> : null}
                        {emailChecked ? <>
                            <TextInput name="forename" value={details.forename}
                                       placeholder="Forename *" disabled={saving}
                                       onChange={(e) => setDetails({...details, forename: e.target.value})}/>
                            <TextInput name="surname" value={details.surname}
                                       placeholder="Surname *" disabled={saving}
                                       onChange={(e) => setDetails({...details, surname: e.target.value})}/>
                            <TextInput name="mobile" value={details.mobile}
                                       placeholder="Mobile Number *" disabled={saving}
                                       onChange={(e) => setDetails({...details, mobile: e.target.value})}/>
                            <label className="field--label">Birthday</label>
                            <DateInput name="dob" onChange={(e) => setDetails({...details, dob: e.target.value})}
                                       value={details.dob}/>
                            <TextInput name="amigo_code" value={details.amigo_code} placeholder="Sign Up Code"
                                       onChange={(e) => setDetails({...details, amigo_code: e.target.value})}/>
                            <Checkbox name="marketing_opt_in" value={details.marketing_opt_in}
                                      label="By ticking this box, I consent to receive any sales or marketing emails from Revolucion de Cuba"/>
                            <Checkbox name="sms_opt_in" value={details.sms_opt_in}
                                      label="By ticking this box, I consent to receive any sales or marketing sms from Revolucion de Cuba"/>
                            <Checkbox name="phone_opt_in" value={details.phone_opt_in}
                                      label="By ticking this box, I consent to receive any sales or marketing phone calls from Revolucion de Cuba"/>
                            <Button name="save" type="submit" text="Register" variant="success" disabled={saving}/>
                        </> : null}
                    </form>
                </ContentBox>}
            </div>
        </PageLayout>
    )
}