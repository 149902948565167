import './App.css';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import Dashboard from "./Modules/Home/Dashboard";
import AppWrapper from "./Modules/State/AppState";
import RegisterAmigo from "./Modules/Register/RegisterAmigo";
import Locations from "./Modules/Locations/Locations";
import Amigo from "./Modules/Amigo/Amigo";
import Verify from "./Modules/Register/Verify";
import Book from "./Modules/Book/Book";
import NotFound from "./Modules/Layout/NotFound/NotFound";
import Login from "./Modules/Login/Login";
import Auth from "./Modules/Auth/Auth";
import JoinAmigoToken from "./Modules/Amigo/JoinAmigoToken";
import MyQrCode from "./Modules/QrCode/MyQrCode";
import BarLanding from "./Modules/Landing/BarLanding";
import ForgotPassword from './Modules/ForgotPassword/ForgotPassword'
import VerifyForgotPassword from './Modules/ForgotPassword/VerifyForgotPassword'

function App() {
    return (
        <AppWrapper>
            <BrowserRouter>
                <div>
                    <Switch>
                        <Route path="/" component={(props) => <Dashboard {...props}/>} exact={true}/>
                        <Route path="/b/:slug" component={(props) => <BarLanding {...props}/>} exact={true}/>
                        <Route path="/register-amigo" component={(props) => <RegisterAmigo {...props}/>} exact={true}/>
                        <Route path="/login" component={(props) => <Login {...props}/>} exact={true}/>
                        <Route path="/forgot-password" component={(props) => <ForgotPassword {...props}/>} exact={true}/>
                        <Route path="/auth" component={(props) => <Auth {...props}/>} exact={true}/>
                        <Route path="/verify/:token"
                               component={(props => <Verify {...props} token={props.match.params.token}/>)}
                               exact={true}/>
                        <Route path="/password-reset/:token"
                               component={(props => <VerifyForgotPassword {...props} token={props.match.params.token}/>)}
                               exact={true}/>
                        <Route path="/locations" component={(props => <Locations {...props} />)} exact={true}/>
                        <Route path="/amigo" component={(props => <Amigo {...props} />)} exact={true}/>
                        <Route path="/qr" component={(props => <MyQrCode {...props} />)} exact={true}/>
                        <Route path="/join-amigo/:token"
                               component={(props => <JoinAmigoToken {...props} token={props.match.params.token}/>)}
                               exact={true}/>
                        <Route path="/book" component={(props => <Book {...props} />)} exact={true}/>
                        <Route status={404} component={(props => <NotFound {...props} />)} exact={true}/>
                    </Switch>
                </div>
            </BrowserRouter>
        </AppWrapper>
    );
}

export default App;
