import React, {createContext, useEffect, useReducer, useState} from 'react';
import {request} from "../../Utility/Api";
import {EventDispatcher} from "../../Utility/Dispatcher";
import Loading from "../Layout/Loading/Loading";
import AppModal from "../../Utility/Modal/AppModal";

const Reducer = (state, action) => {
    switch (action.type) {
        case 'SET_USER':
            return {
                ...state,
                user: action.payload
            };
        case 'SET_LOGGED_IN':
            return {
                ...state,
                logged_in: action.payload.logged_in,
                error: action.payload.error
            };

        case 'SET_ALL_DATA':
            return action.payload
        default:
            return;
    }
};

const initialState = {
    user: null,
    logged_in: false,
    panels: [],
    brand: {}
}
const AppWrapper = ({children}) => {
    const [state, dispatch] = useReducer(Reducer, initialState);
    const [loading, setLoading] = useState(true);
    const reloadAppState = () => {
        setLoading(true);
        request('GET', 'customers/web-app-status?include=user.amigo,user.favourite_bars,user.qr_code,user.pkpass', {}).then((response) => {
            if (response.ok) {
                let user = response.data.data.user.data;
                dispatch({
                    type: 'SET_ALL_DATA', payload: {
                        user: user,
                        logged_in: !!user.rb_customer_id,
                        error: null,
                        brand: response.data.data.brand.data,
                        panels: response.data.data.panels.data
                    }
                });
                response.data.data.css.data.forEach((entry) => {
                    window.load_css(entry);
                });
                document.getElementById('main_body').className = document.getElementById('main_body').className + ' ' + response.data.data.brand.data.short_name;
                setLoading(false);
                return;
            }
            dispatch({type: 'SET_LOGGED_IN', payload: {logged_in: false, error: response}});
            setLoading(false);
        })
    }
    useEffect(() => {
        reloadAppState();
        EventDispatcher.subscribe('reload-app-data', () => reloadAppState());
    }, []);
    return (
        <AppState.Provider value={[state, dispatch]}>
            <AppModal />
            {loading ? <Loading full_page={true}/> : null}
            {state.brand.id ? children : null}
        </AppState.Provider>
    )
}

export const AppState = createContext(initialState);

export default AppWrapper;