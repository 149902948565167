import React, {useEffect} from 'react';
import {withRouter} from 'react-router-dom';
import Loading from "../Layout/Loading/Loading";
import qs from 'qs';
import {request} from "../../Utility/Api";

const Auth = (props) => {
    useEffect(() => {
        let params = qs.parse(props.location.search, {ignoreQueryPrefix: true});
        if (params.code) {
            request('POST', 'customers/oauth/token', {
                body: {
                    token: params.code,
                    state: params.state
                }
            }).then((response) => {
                if (response.ok) {
                    document.location.href = '/';
                    return;
                }
                //document.location.href = '/auth';
            });
        } else {
            request('GET', 'customers/oauth', {}).then((response) => {
                document.location.href = response.data.data.url;
            })
        }
    }, []);


    return <div>
        <Loading/>
    </div>
}

export default withRouter(Auth);