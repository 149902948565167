import React, {useContext, useState} from 'react';
import {AppState} from "../State/AppState";
import Panel from "../Panels/Panel";
import ContentBox from "../Layout/ContentBox/ContextBox";
import PageLayout from "../Layout/PageLayout/PageLayout";

export default function Dashboard(props) {
    const [loading] = useState(false);
    const [appState] = useContext(AppState);
    if (loading) {
        return <div>Loading....</div>
    }
    return (<PageLayout {...props}>
        <ContentBox name="panels">
            {appState.panels.map((panel) => {
                return <Panel panel={panel} key={panel.id}/>
            })}
        </ContentBox>
    </PageLayout>)
}