import React, {Component, Fragment} from 'react';
import Modal from 'react-modal';
import './Modal.css';
import cx from 'classnames';
import {EventDispatcher} from "../Dispatcher";

Modal.setAppElement('#root');

class AppModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            content: null,
            duplicate: false,
            buttons: null,
            top_buttons: null,
            style: 'standard',
            close_on_overlay: false
        }
    }

    componentDidMount() {
        if (!this.state.duplicate) {
            EventDispatcher.subscribe('open-modal', (data) => {
                this.setState({
                    open: true,
                    buttons: data.buttons ? data.buttons : null,
                    top: data.top ? data.top : null,
                    content: data.content,
                    close_on_overlay: true,
                    style: data.style ? data.style : 'standard'
                }, () => {
                    EventDispatcher.dispatch('update-height');
                });
            });
            EventDispatcher.subscribe('close-modal', () => {
                this.setState({open: false, content: null, style: 'standard'}, () => {
                    EventDispatcher.dispatch('update-height');
                });
            });

            EventDispatcher.subscribe('set-modal-buttons', (buttons) => {
                this.setState({buttons: buttons});
            });
            EventDispatcher.subscribe('set-top-modal-buttons', (buttons) => {
                this.setState({top_buttons: buttons});
            });
            EventDispatcher.subscribe('set-close-on-overlay', (value) => {
                this.setState({close_on_overlay: value});
            });
        }
    }

    render() {
        let Settings = {};
        if (this.state.duplicate) {
            return <Fragment/>
        }
        let style = {};
        if (this.state.top) {
            style.content = {top: this.state.top + 'px'};
        }
        return <Modal isOpen={this.state.open} shouldCloseOnOverlayClick={this.state.close_on_overlay}
                      onRequestClose={() => {
                          EventDispatcher.dispatch('close-modal');
                      }} className={cx('modal--modal ', Settings.brand, {'has-buttons': !!this.state.buttons})}
                      overlayClassName="modal--overlay" portalClassName={"rbg-modal rbg-modal--" + this.state.style}
                      style={style}>
            <div className="modal__top-buttons">
                {this.state.top_buttons}
                <button className="modal-close" onClick={() => {
                    EventDispatcher.dispatch('close-modal');
                }}><i className="fa fa-times"/></button>
            </div>
            <div className="modal__content">{this.state.content}</div>
            {this.state.buttons ? <div className="modal__buttons">{this.state.buttons}</div> : null}
        </Modal>
    }
}

export default AppModal;

const ModalHelper = {
    openModal: function (content, style, buttons, top) {
        EventDispatcher.dispatch('open-modal', {
            content: content,
            style: style,
            buttons: buttons,
            top: top
        });
    },
    closeModal: function () {
        EventDispatcher.dispatch('close-modal');
    },
    setButtons: function (buttons) {
        EventDispatcher.dispatch('set-modal-buttons', buttons);
    },
    setTopButtons: function (buttons) {
        EventDispatcher.dispatch('set-top-modal-buttons', buttons);
    },
    setCloseOnOverlayClick: function (value) {
        EventDispatcher.dispatch('set-close-on-overlay', value);
    }
}

export {ModalHelper};