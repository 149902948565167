import React, {useContext, useEffect, useState} from 'react';
import PageLayout from "../Layout/PageLayout/PageLayout";
import ContentBox from "../Layout/ContentBox/ContextBox";
import {AppState} from "../State/AppState";
import {request} from "../../Utility/Api";
import LocationPanel from "./LocationPanel";
import './Locations.scss';
import Loading from "../Layout/Loading/Loading";

export default function Locations(props) {
    /**
     * @todo This component is unfinished, needs finishing if we are going to use it
     * 
     */
    const [globalAppState] = useContext(AppState);
    const [bars, setBars] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        request('GET', 'customers/web-app/bars/' + globalAppState.brand.id, {}).then((response) => {
            if (response.ok) {
                let favBarIds = [];
                if (globalAppState.user.id) {
                    favBarIds = globalAppState.user.favourite_bars.data.map((b) => {
                        return b.bar_id;
                    })
                }
                setLoading(false);
                let bars = response.data.data.map(function (b) {
                    if (favBarIds.indexOf(b.bar_id) > -1) {
                        b.favourite = true;
                    } else {
                        b.favourite = false;
                    }
                    return b;
                });
                setBars(bars);
            }
        })
    }, [props.history.location.path])
    return (
        <PageLayout {...props}>
            <ContentBox name="locations">
                {loading ? <Loading/> : null}
                {bars.map(function (location) {
                    return <LocationPanel location={location}/>
                })}
            </ContentBox>
        </PageLayout>
    );
}