import React, {useEffect, useState} from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';


export default function TextInput(props) {
    let className = 'field__element';
    let type = 'text';
    let pattern = null;
    if (props.number === true) {
        type = 'number';
        pattern = '[0-9]*';
    }
    if (props.email === true) {
        type = 'email';
    }
    const [value, setValue] = useState(props.value);
    const onChange = (e) => {
        setValue(e.target.value);
        if (props.onChange) {
            props.onChange(e);
        }
    }
    useEffect(() => {
        if (value !== props.value) {
            setValue(props.value);
        }
    }, [props, value]);

    return (
        <div
            className={cx('field', 'field--text', 'field--' + props.name, props.wrapperClass, {'field--error': props.error})}>
            <div className="field__element-wrap">
                <input type={type} name={props.name} id={props.id ? props.id : props.name}
                       value={value}
                       pattern={pattern}
                       className={className} onChange={e => onChange(e)}
                       disabled={props.disabled}
                       onBlur={(e) => {
                           if (props.onBlur) {
                               props.onBlur(e);
                           }
                       }}
                       placeholder={props.placeholder}
                       onFocus={(e) => {
                           if (props.onFocus) {
                               props.onFocus(e);
                           }
                       }} maxLength={props.maxLength}
                       max={props.max}
                       min={props.min}/>
            </div>
            {props.error ? <div className="field__error">{props.error}</div> : null}
        </div>
    )
}

TextInput.propTypes = {
    name: PropTypes.string.isRequired,
    id: PropTypes.string,
    wrapperClass: PropTypes.string,
    placeholder: PropTypes.string,
    max: PropTypes.number,
    min: PropTypes.number,
    error: PropTypes.string
}