import React from 'react';
import {NavLink} from 'react-router-dom'
import PageLayout from "../PageLayout/PageLayout";
import ContentBox from "../ContentBox/ContextBox";
import './NotFound.scss'

export default function NotFound(props) {
    return <PageLayout {...props}>
        <ContentBox name="not-found">
            <div className="NotFound-container">
                <div className="NotFound-main">
                    <div className="NotFound-headers">
                        <h1>Oops!</h1> 
                        <h3>We couldn't find that page</h3>
                    </div>
                    <div className="NotFound-guidance">
                        <p>Maybe you can find what you need here?</p>
                        <img src="assets/images/arrow.svg"/>
                    </div>
                </div>
                <div className="NotFound-links">
                    <NavLink className="link-to" to="/">Home</NavLink>
                    <a className="link-to" href="https://order.decuba.app">Order And Pay</a>
                    <NavLink className="link-to" to="/login">Login</NavLink>
                </div>
            </div>
        </ContentBox>
    </PageLayout>
}