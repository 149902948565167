import React from 'react';
import PropTypes from 'prop-types';
import './Button.scss';
import cx from 'classnames';

export default function Button(props) {
    let variant_class = '';
    if (props.variant) {
        variant_class = 'btn--' + props.variant;
    }
    if (props.onClick) {
        return <button className={cx('btn', 'btn--' + props.name, variant_class)} tabIndex={props.tabIndex}
                       type={props.type}
                       disabled={props.disabled} onClick={(e) => props.onClick(e)}>
            <span>{props.text}</span>
        </button>
    }
    return <button className={cx('btn', 'btn--' + props.name, variant_class)} tabIndex={props.tabIndex}
                   type={props.type}
                   disabled={props.disabled}>
        <span>{props.text}</span>
    </button>
}

Button.propTypes = {
    text: PropTypes.any.isRequired,
    onClick: PropTypes.func,
    circular: PropTypes.bool,
    name: PropTypes.string.isRequired,
    variant: PropTypes.string,
    disabled: PropTypes.bool,
    tabIndex: PropTypes.number,
    type: PropTypes.string
};