import { withRouter } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { request } from '../../Utility/Api'
import PageLayout from '../Layout/PageLayout/PageLayout'
import ContentBox from '../Layout/ContentBox/ContextBox'
import Notice from '../Layout/Notice/Notice'
import PasswordInput from '../../Form/PasswordInput'
import Button from '../../Form/Button'
import Loading from '../Layout/Loading/Loading'

export default withRouter(function VerifyForgotPassword (props) {
      const [password, setPassword] = useState('')
      const [error, setError] = useState(null)
      const [loading, setLoading] = useState(true)

      const [user, setUser] = useState({})

      const reset = (e) => {
          e.preventDefault();
          setLoading(true);
          request('POST', 'customers/forgot-password/' + props.token + '/process', {
              body: { password: password },
          }).then((response) => {
              setLoading(false);
              if (response.ok) {
                  props.history.push('/auth')
                  return
              }
              setError(response.error)
          })
      }

      useEffect(() => {
          request('GET', 'customers/forgot-password/' + props.token + '/verify', {}).then((response) => {
              if (response.ok) {
                  setUser(response.data.data)
                  setLoading(false)

                  return
              }
              let message = response.error
              if (response.code === 404) {
                  message = 'Sorry that token is no longer valid or cannot be found'
              }
              setError(message)
              setLoading(false)
          })
      }, [])

      if (loading) {
          return <Loading/>
      }

      if (error) {
          return (<PageLayout {...props}>
              <ContentBox>
                  <Notice name="error" variant="error">{error}</Notice>
              </ContentBox></PageLayout>)
      }

      return (<PageLayout {...props}>
          <ContentBox title={'Hi ' + user.forename + '. Enter your new password below!'}>
              <form onSubmit={(e) => reset(e)}>
                  <PasswordInput name="password" value={password}
                                 placeholder="Password"
                                 onChange={(e) => setPassword(e.target.value)}/>
                  <Button name="login" text="Reset My Password" variant="full-width" type="submit"/>
              </form>
          </ContentBox>
      </PageLayout>)
  },
)