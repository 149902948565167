import React from 'react';
import './Loading.scss';

function Loading(props) {
    if (props.full_page === true) {
        return <div className="full-page-load">
            <div className="loading-icon">
                <i className="fa loading-icon fa-spin"/>
            </div>
        </div>
    }
    return <div className="loading-icon">
        <i className="fa loading-icon fa-spin"/>
    </div>
}

export default Loading;