import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

export default function PanelBox(props) {
    let style = {
        backgroundImage: `url(${props.image})`
    };
    if (props.action) {
        style.cursor = 'pointer';
    }
    return (
        <div className={cx('panel', props.name ? 'panel--' + props.name : '')} style={style} onClick={(e) => {
            if (props.action) {
                props.action(e);
            }
        }}>
            <div className="panel--titles">
                {props.title ? <h2 className="panel--titles--title">{props.title}</h2> : null}
                {props.sub_title ? <h3 className="panel--titles--sub-title">{props.sub_title}</h3> : null}
            </div>
        </div>
    )
}

PanelBox.propTypes = {
    image: PropTypes.string.isRequired,
    action: PropTypes.func,
    title: PropTypes.string,
    sub_title: PropTypes.string
}