import React, {useEffect, useState} from 'react';
import cx from "classnames";
import PropTypes from 'prop-types';

export default function SelectInput(props) {
    let className = 'field__element';
    const [value, setValue] = useState(props.value);
    const onChange = (e) => {
        setValue(e.target.value);
        if (props.onChange) {
            props.onChange(e);
        }
    }
    useEffect(() => {
        if (value !== props.value) {
            setValue(props.value);
        }
    }, [props, value]);

    return (
        <div
            className={cx('field', 'field--select', 'field--' + props.name, props.wrapperClass, {'field--error': props.error})}>
            <div className="field__element-wrap">
                <select name={props.name} id={props.id ? props.id : props.name}
                        value={value}
                        className={className} onChange={e => onChange(e)}
                        disabled={props.disabled}
                        onBlur={(e) => {
                            if (props.onBlur) {
                                props.onBlur(e);
                            }
                        }}
                        placeholder={props.placeholder}
                        onFocus={(e) => {
                            if (props.onFocus) {
                                props.onFocus(e);
                            }
                        }}>
                    {props.includeBlankOption ? <option value="">- Select - </option> : null}
                    {props.values.map((v) => {
                        return <option value={v.key} key={v.key}>{v.value}</option>
                    })}
                </select>
            </div>
            {props.error ? <div className="field__error">{props.error}</div> : null}
        </div>
    )
}

SelectInput.propTypes = {
    name: PropTypes.string.isRequired,
    values: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    error: PropTypes.bool,
    id: PropTypes.string,
    disabled: PropTypes.bool,
    onBlur: PropTypes.func,
    placeholder: PropTypes.string,
    onFocus: PropTypes.func,
    includeBlankOption: PropTypes.bool,
}